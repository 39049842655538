import { useEffect, useState } from 'react'

import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import styled from 'styled-components'
import { useAppDispatch } from '../store/hooks'
import { toggleTheme } from '../store/reducer/ui'

import Homepage from './page/homepage/Homepage'

import Details from './page/detail/Details'
import Header from './component/shared/header/Header'
import Footer from './component/shared/footer/Footer'

const Main = styled.div`
    margin: 48px 0;
`
export function App() {
    const [router, setRouter] = useState(null)
    const dispatch = useAppDispatch()

    useEffect(() => {
        const isCiat = location.pathname.includes('/ciat')
        const isCarrier = location.pathname.includes('/carrier')
        const basename = isCiat ? '/ciat' : '/carrier'
        // Redirect to carrier , when no model is selected
        if (!isCiat && !isCarrier) {
            window.location = '/carrier'
        }


        if (isCiat) {
            dispatch(toggleTheme())
        }
        setRouter(
            createBrowserRouter(
                [
                    {
                        path: '/',
                        element: <Homepage />,
                    },
                    {
                        path: '/model/:selectedModel',
                        element: <Details />,
                    },
                ],
                {
                    basename,
                }
            )
        )
    }, [])

    return (
        <>
            {router && (
                <div>
                    <Header />
                    <Main>
                        <RouterProvider {...{ router }} />
                    </Main>
                    <Footer />
                </div>
            )}
        </>
    )
}
