import styled from 'styled-components'
import React, { useMemo, useState, useEffect } from 'react'
import FileSaver from 'file-saver'

import {
    Divider,
    IconButton,
    ListItemText,
    ListItem,
    List,
    DialogContent,
    DialogActions,
    Dialog,
    Button,
    Badge,
} from '@mui/material'

import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import DescriptionIcon from '@mui/icons-material/Description'
import DownloadIcon from '@mui/icons-material/Download'
import { translate } from 'utils/intl/helper'
import { KEYS } from 'utils/intl/constant'
import { useAppSelector, useAppDispatch } from '../../../../store/hooks'
import { selectList, addNewList as reportAddList } from '../../../../store/reducer/report'

const Container = styled.div``

function ReportList({ list, onClick, onDelete, onDownload }) {
    return (
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {list.map((item, index) => (
                <React.Fragment key={index}>
                    <ListItem key={index}>
                        <ListItemText primary={`${item.model} (${item.size}) `} />
                        <IconButton onClick={() => onClick(index)}>
                            <VisibilityIcon />
                        </IconButton>
                        <IconButton onClick={() => onDownload(index)}>
                            <DownloadIcon />
                        </IconButton>
                        <IconButton onClick={() => onDelete(index)}>
                            <DeleteForeverIcon />
                        </IconButton>
                    </ListItem>
                    {list.length !== index + 1 && <Divider light />}
                </React.Fragment>
            ))}
        </List>
    )
}

const ReportNotification = () => {
    const [open, setOpen] = useState(false)
    const [preview, setPreview] = useState(false)
    const reportList = useAppSelector((state) => selectList(state.report))
    const intl = useAppSelector((state) => state.ui.intl)

    const dispatch = useAppDispatch()
    const list = useMemo(
        () =>
            reportList.map((report) => ({
                pdfBlob: report?.pdfBlob,
                model: report?.model,
                size: report?.size,
            })) ?? [],
        [reportList],
    )

    const handleClickOpen = () => {
        setOpen(true)
        setPreview(false)
    }

    const handleClose = () => {
        setOpen(false)
        setPreview(false)
    }

    const handleDownload = (index) => {
        if (list[index] && list[index].pdfBlob) {
            FileSaver.saveAs(URL.createObjectURL(list[index].pdfBlob), `TDS-${list[index].model}.pdf`)
        }
    }

    const handleClickPreview = (index) => {
        setPreview(index)
    }

    const handleClickDeletePreview = (index) => {
        const newList = [...reportList]
        newList.splice(index, 1)
        dispatch(reportAddList(newList))
    }

    const handleClickDeleteAll = () => {
        dispatch(reportAddList([]))
    }

    useEffect(() => {
        if (list.length > 0 && !open && !preview) {
            setPreview(list.length - 1)
        }
    }, [list])

    return (
        <Container>
            <div onClick={handleClickOpen}>
                <Badge badgeContent={list.length} color='error'>
                    <DescriptionIcon />
                </Badge>
            </div>
            <Dialog maxWidth={false} open={list.length > 0 && open} onClose={handleClose}>
                <DialogContent>
                    {preview === false && list.length > 0 && (
                        <ReportList
                            list={list}
                            onClick={handleClickPreview}
                            onDelete={handleClickDeletePreview}
                            onDownload={handleDownload}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{translate(intl, KEYS.UI_CLOSE)}</Button>
                    <Button onClick={handleClickDeleteAll}>{translate(intl, KEYS.UI_REMOVEALL)}</Button>
                </DialogActions>
            </Dialog>
            <Dialog maxWidth={false} open={preview !== false} onClose={handleClose}>
                <DialogContent>
                    {list[preview] && list[preview].pdfBlob && (
                        <iframe
                            src={URL.createObjectURL(list[preview].pdfBlob)}
                            style={{ height: '80vh', width: '60vw' }}></iframe>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{translate(intl, KEYS.UI_CLOSE)}</Button>
                    <Button onClick={() => handleDownload(preview)}>{translate(intl, KEYS.UI_DOWNLOAD)}</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

export default ReportNotification
