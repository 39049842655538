export const endpoint = { baseURL: process.env.APP_API_URL }

export const backend = {
    SEARCH_ALL_MODEL: endpoint.baseURL + '/nosql/search_all/model',
    SEARCH_MODEL: endpoint.baseURL + '/nosql/search/model/',
    OPTIONS_BY_MODEL: endpoint.baseURL + '/nosql/search/options/model/',
    SIZES_BY_MODEL: endpoint.baseURL + '/nosql/search/sizes/model/',
    CALC_TYPES_BY_MODEL: endpoint.baseURL + '/nosql/search/calcTypes/model/',
    GET_ALL_INFOS: endpoint.baseURL + '/nosql/search/infos/',
    DOWNLOAD: endpoint.baseURL + '/download',
    GENERATE_TDS: endpoint.baseURL + '/nosql/generate_pdf/',
    TRANSLATION: endpoint.baseURL + '/translation',
}

export const ui = {
    CIAT: 'ciat',
    CARRIER: 'carrier',
    STATIC_ASSETS_STORAGE_URL: 'https://ecatstaticfiles.carrier.com/ecatui/ecatimages/',
}

export default { endpoint, ui, backend }
