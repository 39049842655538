import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ModelState {
    list: any
    filtred: any
    selected: any
    options: any
}

const initialState: ModelState = {
    list: [],
    filtred: [],
    selected: undefined,
    options: undefined,
}

export const modelSlice = createSlice({
    name: 'model',
    initialState,
    reducers: {
        addList: (state, action: PayloadAction<any>) => {
            state.list = action.payload
        },
        addModelFiltred: (state, action: PayloadAction<any>) => {
            state.filtred = action.payload
        },
        addModelSelected: (state, action: PayloadAction<any>) => {
            state.selected = action.payload
        },
        addOptions: (state, action: PayloadAction<any>) => {
            state.options = action.payload
        },
        resetAll: (state) => {
            state.list = []
            state.filtred = []
            state.options = undefined
            state.selected = undefined
        },
    },
})

export const selectList = (state: ModelState) => state.list
export const selectSelected = (state: ModelState) => state.selected
export const selectFiltred = (state: ModelState) => state.filtred
export const selectOptions = (state: ModelState) => state.options

export const { addList, addModelFiltred, addModelSelected, resetAll, addOptions } = modelSlice.actions

export default modelSlice.reducer
