import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { red, blue } from '@mui/material/colors'
import { useAppSelector } from '../../store/hooks'
import { selectTheme } from '../../store/reducer/ui'

export const themeCarrier = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#152e70',
        },
    },
})

export const themeCiat = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#e40e12',
        },
    },
})

type Props = {
    children: JSX.Element | JSX.Element[]
}

export const MuiProvider = ({ children }: Props) => {
    const isCiat = useAppSelector((state) => selectTheme(state.ui))
    const theme = isCiat ? themeCiat : themeCarrier
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    )
}
