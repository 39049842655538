export const KEYS = {
    UI_SIZE: 'Size',
    UI_UNIT_TYPE: 'Unit type',
    UI_OPTION: 'Option',
    UI_SEARCH: 'Search',
    UI_OPERATINGMODE: 'Operating mode',
    UI_REFRIGERANT: 'Refrigerant',
    UI_LASTUPDATE: 'Last update',
    UI_CALCTYPE: 'Calculation type',
    UI_SEERLT: 'SEER LT (12°C/7°C)',
    UI_SEERMT: 'SEER MT (23°C/18°C)',
    UI_SEPRMT: 'SEPR MT (-2°C/-8°C)',
    UI_SEPRHT: 'SEPR HT (12°C/7°C)',
    UI_SCOPMT: 'SCOP MT (47°C/55°C)',
    UI_SCOPLTA: 'SCOP LTa (30°C/35°C)',
    UI_SCOPLTW: 'SCOP LTw (30°C/35°C)',
    UI_SCOPLTC: 'SCOP LTc (30°C/35°C)',
    UI_SCOPITA: 'SCOP ITa (40°C/45°C)',
    UI_SCOPITW: 'SCOP ITw (40°C/45°C)',
    UI_SCOPITC: 'SCOP ITc (40°C/45°C)',
    UI_SCOPMTA: 'SCOP MTa (47°C/55°C)',
    UI_SCOPMTW: 'SCOP MTw (47°C/55°C)',
    UI_SCOPMTC: 'SCOP MTc (47°C/55°C)',
    UI_SCOPHTA: 'SCOP HTa (55°C/65°C)',
    UI_SCOPHTW: 'SCOP HTw (55°C/65°C)',
    UI_SCOPHTC: 'SCOP HTc (55°C/65°C)',
    UI_CLOSE: 'Close',
    UI_DOWNLOAD: 'Download',
    UI_REMOVEALL: 'Remove all',
    UI_NOTICE: 'Privacy notice',
    UI_TERMS: 'Terms of use',
    UI_COMPANY: 'A Carrier Company',
    UI_RIGHTS: 'All rights reserved',
    UI_PAGINATION: 'Candidate per page',
    UI_ACCESSORIES: 'Accessories',
    UI_COOLINGONLY: 'Cooling only',
    UI_REVERSIBLEUNIT: 'Reversible unit',
    UI_HEATINGONLY: 'Heating only',
    UI_AIRTOWATER: 'Air to water',
    UI_SELECTALLSIZES: 'Select all sizes',
    UI_SELECTALLOPTIONS: 'Select all options',
    UI_SELECTCALCULATIONTYPES: 'Select calculation types',
    UI_TDSGENERATION_PROGRESS: 'TDS generation is in progress',
    UI_TDSGENERATION_ERROR: 'An error occured when generating the TDS',
    UI_COMFORT: 'Comfort',
    UI_PROCESS: 'Process',
    UI_SPACEHEATER: 'Space heater',
    UI_LOWTEMPERATURE: 'Low temperature',
    UI_MEDIUMTEMPERATURE: 'Medium temperature',
    UI_HIGHTEMPERATURE: 'High temperature',
    UI_NO_RESULTS_FOUND: 'No results found',
}
