import { configureStore } from '@reduxjs/toolkit'

import ui from './reducer/ui'
import model from './reducer/model'
import report from './reducer/report'
import candidate from './reducer/candidate'

export const store = configureStore({
    reducer: { ui, model, report, candidate },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
