import Grid from '@mui/material/Grid'
import styled from 'styled-components'

type Props = {
    children: JSX.Element | JSX.Element[]
    right?: boolean
}

type StyledProps = {
    [key: string]: React.ReactNode
}

const StyledGridRight = styled(Grid)<Props & StyledProps>`
    && {
        border-left: 1px solid rgb(229, 231, 237);
        display: flex;
        padding-left: 16px;
        justify-content: flex-start;

        @media (max-width: 1200px) {
            justify-content: center;
            border-right: unset;
        }
    }
`

const StyledGridLeft = styled(Grid)<Props & StyledProps>`
    && {
        border-right: 1px solid rgb(229, 231, 237);
        display: flex;
        justify-content: flex-end;

        @media (max-width: 1200px) {
            justify-content: center;
            border-right: unset;
        }
    }
`

const Aside = ({ children, right }: Props) => {
    return (
        <>
            {right ? (
                <StyledGridRight item xs={12} md={1}>
                    {children}
                </StyledGridRight>
            ) : (
                <StyledGridLeft item xs={12} md={2}>
                    {children}
                </StyledGridLeft>
            )}
        </>
    )
}

export default Aside
