import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface CandidateState {
    list: any
    listFiltred: any
    selected: any
}

const initialState: CandidateState = {
    list: [],
    listFiltred: [],
    selected: undefined,
}

export const candidateSlice = createSlice({
    name: 'candidate',
    initialState,
    reducers: {
        addSelected: (state, action: PayloadAction<any>) => {
            state.selected = action.payload
        },
        removeSelected: (state) => {
            state.selected = initialState.selected
        },
        addList: (state, action: PayloadAction<any>) => {
            state.list = action.payload
        },
        addListFiltred: (state, action: PayloadAction<any>) => {
            state.listFiltred = action.payload
        },
        removeListFiltred: (state) => {
            state.listFiltred = initialState.listFiltred
        },
        resetAll: (state) => {
            state.selected = undefined
            state.list = []

            state.listFiltred = []
        },
    },
})

export const { addList, addSelected, removeSelected, addListFiltred, removeListFiltred, resetAll } =
    candidateSlice.actions

export const selectList = (state: CandidateState) => state.list
export const selectSelected = (state: CandidateState) => state.selected
export const selectListFiltred = (state: CandidateState) => state.listFiltred

export default candidateSlice.reducer
