import styled from 'styled-components'
import Grid from '@mui/material/Grid'

const StyledGrid = styled(Grid)`
    && {
        //background: rgba(71, 126, 87, 0.5);
        /* @media (max-width: 1200px) {
            min-height: calc(100vh - 382px);
        } */
    }
`

type Props = {
    children: JSX.Element | JSX.Element[]
}

const Content = ({ children }: Props) => {
    return (
        <StyledGrid item xs={12} md={9}>
            {children}
        </StyledGrid>
    )
}

export default Content
