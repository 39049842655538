import React, { useEffect, useState, useCallback } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { Pagination, Grid, Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

import constant from 'utils/constant'
import MediaCard from '../../component/shared/card/MediaCard'
import useInitPage from '../../hooks/useInitHomePage'
import { getFilename } from '../../utils/functions'
import FilterHome from '../../page/left/FilterHome'
import Content from '../../component/template/Content'
import Aside from '../../component/template/Aside'
import { useAppSelector } from '../../../store/hooks'
import { selectTheme } from '../../../store/reducer/ui'
export const Container = styled(Box)`
    margin: 0 24px;
`

const StyledBox = styled(Box)`
    flex-grow: 1;
    display: flex;
    justify-content: right;
`

const StyledLoading = styled.div`
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
`
const StyledGrid = styled(Grid)`
    min-height: calc(100vh - 190px);
    @media (max-width: 1200px) {
        min-height: calc(100vh - 280px);
    }
`
const Homepage = () => {
    const [checkedTypes, setCheckedTypes] = useState([])
    const [defaultTypes, setDefaultTypes] = useState()
    const [checkedModes, setCheckedModes] = useState([])
    const [defaultModes, setDefaultModes] = useState([])
    const [checkedRefrigerants, setCheckedRefrigerants] = useState([])
    const [defaultRefrigerants, setDefaultRefrigerants] = useState([])

    const isCiat = useAppSelector((state) => selectTheme(state.ui))

    const fetchInfos = useCallback(async () => {
        try {
            const { data } = await axios.get(constant.backend.GET_ALL_INFOS, {
                params: {
                    company: isCiat ? 'ciat' : 'carrier',
                },
            })
            const types = data.allTypes
            const refrigerants = data.allRefrigerants
            const modes = data.allModes
            const uniqueTypes = [...new Set(types.map(JSON.stringify))].map(JSON.parse)
            const checkedTypes = uniqueTypes.map((type) => ({ label: type, checked: true }))
            setDefaultTypes(uniqueTypes)
            setCheckedTypes(checkedTypes)
            const uniqueRefrigerants = [...new Set(refrigerants.map(JSON.stringify))].map(JSON.parse)
            const checkedRefrigerants = uniqueRefrigerants.map((refrigerant) => ({ label: refrigerant, checked: true }))
            setDefaultRefrigerants(uniqueRefrigerants)
            setCheckedRefrigerants(checkedRefrigerants)
            const uniqueModes = [...new Set(modes.map(JSON.stringify))].map(JSON.parse)
            const checkedModes = uniqueModes.map((mode) => ({ label: mode, checked: true }))
            setDefaultModes(uniqueModes)
            setCheckedModes(checkedModes)
        } catch (error) {
            console.error(error)
        }
    }, [])
    const { modelListFiltered, handleChangePage, page, rowsPerPage, totalRows } = useInitPage(
        checkedModes,
        checkedTypes,
        checkedRefrigerants
    )
    useEffect(() => {
        fetchInfos()
    }, [])

    return (
        <StyledGrid container>
            <Aside>
                <FilterHome
                    checkedTypes={checkedTypes}
                    setCheckedTypes={setCheckedTypes}
                    checkedModes={checkedModes}
                    setCheckedModes={setCheckedModes}
                    checkedRefrigerants={checkedRefrigerants}
                    setCheckedRefrigerants={setCheckedRefrigerants}
                />
            </Aside>
            {modelListFiltered.length > 0 ? (
                <Content>
                    <Container>
                        <Grid container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            {modelListFiltered.map(({ model, info }) => (
                                <Grid key={model} item xs={6} sm={4} md={4} lg={3} xl={2}>
                                    <MediaCard
                                        {...{
                                            title: model,
                                            to: '/model/' + model,
                                            image: `${constant.ui.STATIC_ASSETS_STORAGE_URL}${getFilename(
                                                info.image.modelImage
                                            )}`,
                                            description: info,
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <StyledBox pt={3}>
                            <Pagination
                                page={page}
                                onChange={handleChangePage}
                                count={Math.round(totalRows / rowsPerPage)}
                                variant='outlined'
                                color='primary'
                                shape='rounded'
                            />
                        </StyledBox>
                    </Container>
                </Content>
            ) : (
                <Content>
                    <StyledLoading>
                        <CircularProgress disableShrink />
                    </StyledLoading>
                </Content>
            )}
        </StyledGrid>
    )
}

export default Homepage
