import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

//

export interface UiState {
    list: any
}

const initialState: UiState = {
    list: [],
}

export const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        addList: (state, action: PayloadAction<any>) => {
            state.list = [...state.list, action.payload]
        },
        addNewList: (state, action: PayloadAction<any>) => {
            state.list = action.payload
        },
    },
})

export const selectList = (state: UiState) => state.list
export const { addList, addNewList } = reportSlice.actions
export default reportSlice.reducer
