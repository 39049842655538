import styled from 'styled-components'
import Grid from '@mui/material/Grid'
import { translate } from 'utils/intl/helper'
import { KEYS } from 'utils/intl/constant'
import { selectTheme } from '../../../../store/reducer/ui'
import { useAppSelector } from '../../../../store/hooks'
import packageJson from '../../../../package.json'

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 8px;
    border-top: 1px solid #e5e7ed;
    font-size: 10px;
`

const Main = styled(Grid)`
    display: flex;
    align-items: center;
    justify-content: center;
    > * {
        padding: 0 24px;
    }
`
const Left = styled(Grid)`
    text-align: left;

    @media (max-width: 1200px) {
        text-align: center;
        padding: 8px;
    }
`
const Center = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1200px) {
        & {
            display: block;
            text-align: center;
            padding: 8px;
        }
    }
`
const Right = styled(Grid)`
    text-align: right;
    @media (max-width: 1200px) {
        text-align: center;
        padding: 8px;
    }
`
const StyledLink = styled.a`
    padding: 0 10px;
    color: grey;
    text-decoration: none;
`

const Footer = () => {
    const isCiat = useAppSelector((state) => selectTheme(state.ui))
    const intl = useAppSelector((state) => state.ui.intl)

    return (
        <Container>
            <Main container>
                <Left item xs={12} lg={3}>
                    <StyledLink
                        href={isCiat ? 'https://www.ciat.com/' : 'https://www.carrier.com'}
                        rel='noreferrer'
                        title='Privacy Policy'
                        target='_blank'>
                        {new Date().getFullYear()} @{isCiat ? 'Ciat' : 'Carrier'}, {translate(intl, KEYS.UI_RIGHTS)}
                    </StyledLink>
                </Left>
                <Center item xs={12} lg={6}>
                    <StyledLink
                        href='https://www.carrier.com/commercial/en/eu/legal/privacy-notice/'
                        rel='noreferrer'
                        title='Privacy Policy'
                        target='_blank'>
                        {translate(intl, KEYS.UI_NOTICE)}
                    </StyledLink>
                    <StyledLink
                        href='https://www.carrier.com/commercial/en/eu/legal/terms-of-use/'
                        title='Legal'
                        rel='noreferrer'
                        target='_blank'>
                        {translate(intl, KEYS.UI_TERMS)}
                    </StyledLink>
                    <StyledLink
                        rel='noreferrer'
                        href='https://www.corporate.carrier.com/'
                        title='Carrier Corporation'
                        target='_blank'>
                        A Carrier company
                    </StyledLink>
                </Center>
                <Right item xs={12} lg={3}>
                    {packageJson.version}
                </Right>
            </Main>
        </Container>
    )
}

export default Footer
