import React from 'react'
import Box from '@mui/material/Box'

import { Collapse, Typography } from '@mui/material'
import styled from 'styled-components'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowRight'

import ListItemText from '@mui/material/ListItemText'

import { KEYS } from 'utils/intl/constant'
import { translate } from 'utils/intl/helper'
import { useAppSelector } from '../../../store/hooks'

const Container = styled.div`
    display: flex;
    position: sticky;
    width: 100%;
`

const StyledBox = styled(Box)`
    color: #0000008a;
    width: 100%;
`

const StyledFormControlLabel = styled(FormControlLabel)`
    margin-bottom: 16px;
`

const StyledFormGroup = styled(FormGroup)`
    padding: 10px;
`

const StyledList = styled(List)`
    border-bottom: 1px solid #e5e7ed;
`

const StyledListFirst = styled(List)`
    border-bottom: 1px solid #e5e7ed;
    border-top: 1px solid #e5e7ed;
`

const TextInfo = styled(Typography)`
    && {
        color: #757575;
        font-size: 14px;
    }
`

const CheckboxLabels = ({ label, name, checked, onChange }) => {
    return (
        <StyledFormControlLabel
            labelPlacement='end'
            control={<Checkbox {...{ checked, onChange, name: name }} size='small' />}
            {...{ label: <TextInfo>{label.split('#')[0]}</TextInfo> }}
        />
    )
}

export default function NonLinearSlider({
    checkedTypes,
    setCheckedTypes,
    checkedModes,
    setCheckedModes,
    checkedRefrigerants,
    setCheckedRefrigerants,
}) {
    const [expanded, setExpanded] = React.useState('unit')
    const intl = useAppSelector((state) => state.ui.intl)

    const handleExpandClick = (category: string) => {
        setExpanded((prev) => (prev === category ? '' : category))
    }

    const toggleChangeMode = (event, value) => {
        setCheckedModes((prev) => {
            const newState = prev.map((obj) => {
                if (obj.label === event.target.name) {
                    return { label: obj.label, checked: value }
                }
                return obj
            })
            return newState
        })
    }
    const toggleChangeType = (event, value) => {
        setCheckedTypes((prev) => {
            const newState = prev.map((obj) => {
                if (obj.label === event.target.name) {
                    return { label: obj.label, checked: value }
                }
                return obj
            })
            return newState
        })
    }

    const toggleChangeRefrigerant = (event, value) => {
        setCheckedRefrigerants((prev) => {
            const newState = prev.map((obj) => {
                if (obj.label === event.target.name) {
                    return { label: obj.label, checked: value }
                }
                return obj
            })
            return newState
        })
    }

    return (
        <Container>
            <StyledBox>
                <StyledListFirst disablePadding>
                    <ListItem
                        onClick={() => handleExpandClick('unit')}
                        secondaryAction={expanded === 'unit' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        disablePadding>
                        <ListItemButton selected={expanded === 'unit'}>
                            <ListItemText primary={translate(intl, KEYS.UI_UNIT_TYPE)} />
                        </ListItemButton>
                    </ListItem>
                </StyledListFirst>

                <Collapse in={expanded === 'unit'} timeout='auto'>
                    <StyledFormGroup>
                        {checkedTypes &&
                            checkedTypes.map((type) => (
                                <CheckboxLabels
                                    key={type.label}
                                    label={translate(intl, `UI_${type.label.toUpperCase()}`)}
                                    checked={type.checked}
                                    name={type.label}
                                    onChange={toggleChangeType}
                                />
                            ))}
                    </StyledFormGroup>
                </Collapse>

                <StyledList disablePadding>
                    <ListItem
                        onClick={() => handleExpandClick('type')}
                        secondaryAction={expanded === 'type' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        disablePadding>
                        <ListItemButton selected={expanded === 'type'}>
                            <ListItemText primary={translate(intl, KEYS.UI_OPERATINGMODE)} />
                        </ListItemButton>
                    </ListItem>
                </StyledList>

                <Collapse in={expanded === 'type'} timeout='auto'>
                    <StyledFormGroup>
                        {checkedModes &&
                            checkedModes.map((mode) => (
                                <CheckboxLabels
                                    key={mode.label}
                                    label={translate(intl, `UI_${mode.label.toUpperCase()}`)}
                                    checked={mode.checked}
                                    name={mode.label}
                                    onChange={toggleChangeMode}
                                />
                            ))}
                    </StyledFormGroup>
                </Collapse>

                <StyledList disablePadding>
                    <ListItem
                        onClick={() => handleExpandClick('refrigerant')}
                        secondaryAction={
                            expanded === 'refrigerant' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                        }
                        disablePadding>
                        <ListItemButton selected={expanded === 'refrigerant'}>
                            <ListItemText primary={translate(intl, KEYS.UI_REFRIGERANT)} />
                        </ListItemButton>
                    </ListItem>
                </StyledList>

                <Collapse in={expanded === 'refrigerant'} timeout='auto'>
                    <StyledFormGroup>
                        {checkedRefrigerants &&
                            checkedRefrigerants.map((refrigerant) => (
                                <CheckboxLabels
                                    key={refrigerant.label}
                                    label={refrigerant.label}
                                    checked={refrigerant.checked}
                                    name={refrigerant.label}
                                    onChange={toggleChangeRefrigerant}
                                />
                            ))}
                    </StyledFormGroup>
                </Collapse>
            </StyledBox>
        </Container>
    )
}
