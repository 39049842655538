import { useEffect } from 'react'
import { createIntl } from 'react-intl'
import constant from 'utils/constant'
import axios from 'axios'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { changeIntl, setLoading } from '../../store/reducer/ui'

export default function useTranslation() {
    const lang: string = useAppSelector((state) => state.ui.lang)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(setLoading(true))
        axios.get(`${constant.backend.TRANSLATION}/${lang}`).then(({ data }) => {
            dispatch(
                changeIntl(
                    createIntl({
                        locale: lang,
                        messages: data,
                    }),
                ),
            )
        })
    }, [lang])
}
