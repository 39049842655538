import { useEffect, useState } from 'react'
import axios from 'axios'

import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { addList, resetAll, selectList, selectFiltred } from '../../store/reducer/model'
import { selectTheme } from '../../store/reducer/ui'
import constant from '../../src/utils/constant'

interface Checked {
    label: string,
    checked: boolean
}

export default function useInitHomePage(
    checkedModes: Array<Checked>,
    checkedTypes: Array<Checked>,
    checkedRefrigerants: Array<Checked>
) {
    const dispatch = useAppDispatch()
    const modelList = useAppSelector((state) => selectList(state.model))
    const isCiat = useAppSelector((state) => selectTheme(state.ui))
    const modelListFiltered = useAppSelector((state) => selectFiltred(state.model))
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(12)
    const [totalRows, setTotalRows] = useState(0)
    useEffect(() => {
        if (checkedRefrigerants.length > 0 || checkedTypes.length > 0 || checkedModes.length > 0) {
            axios
                .get(constant.backend.SEARCH_ALL_MODEL + `/${rowsPerPage}/${page}`, {
                    params: {
                        company: isCiat ? 'ciat' : 'carrier',
                        types: [...checkedTypes.filter((type) => type.checked).map((type) => type.label)].join(','),
                        modes: [...checkedModes.filter((mode) => mode.checked).map((mode) => mode.label)].join(','),
                        refrigerants: [
                            ...checkedRefrigerants
                                .filter((refrigerant) => refrigerant.checked)
                                .map((refrigerant) => refrigerant.label),
                        ].join(','),
                    },
                })
                .then(({ data }) => {
                    if (totalRows === 0) {
                        setTotalRows(data.totalResult)
                    }
                    dispatch(addList(data.result))
                })

            return () => {
                dispatch(resetAll())
            }
        }
    }, [page, checkedModes, checkedTypes, checkedRefrigerants])

    const handleChangePage = (_: unknown, newPage: number) => {
        setPage(newPage)
    }
    return { modelList, modelListFiltered, handleChangePage, page, setPage, rowsPerPage, totalRows }
}
