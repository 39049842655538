import * as React from 'react'
import styled from 'styled-components'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'

const StyledAutocomplete = styled(Autocomplete)`
    .MuiOutlinedInput-root {
        background: rgba(255, 255, 255, 0.5);
        color: white;
        border: none;
        padding: 0;
    }

    & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border: none;
    }
`

const StyledTextField = styled(TextField)`
    /* & > label {
        padding-left: 32px !important;
    }

    & > div > input {
        padding-left: 12px !important;
    } */
`

const StyledInputAdornment = styled(InputAdornment)`
    color: grey;
    background: rgba(255, 255, 255, 0.85);
    border-radius: 5px;
    padding: 10px;
    height: 100%;
`

interface Option {
    label: string
    value: string
}

interface Props {
    label: string
    options: Option[]
    onInputChangeCallback?: (event: React.SyntheticEvent, value: string, reason: string) => void
}

export default function SearchBar({ label, options, onInputChangeCallback }: Props) {
    return (
        <>
            <StyledAutocomplete
                onInputChange={onInputChangeCallback}
                options={options.map((option) => option.label)}
                freeSolo
                renderInput={(params) => (
                    <div>
                        <StyledTextField
                            {...params}
                            placeholder={label}
                            variant='outlined'
                            InputLabelProps={{ shrink: false }}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <StyledInputAdornment position='start'>
                                        <SearchIcon />
                                    </StyledInputAdornment>
                                ),
                            }}
                        />
                    </div>
                )}
            />
        </>
    )
}
