import { createRoot } from 'react-dom/client'

import { Provider } from 'react-redux'
import { store } from '../store/redux'
import { App } from './App'
import { ProviderApp } from './ProviderApp'

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
    <Provider store={store}>
        <ProviderApp>
            <App />
        </ProviderApp>
    </Provider>
)
