import * as React from 'react'
import styled from 'styled-components'

import {
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Select,
    MenuItem,
    Menu,
    SelectChangeEvent,
    LinearProgress,
} from '@mui/material'

import Tune from '@mui/icons-material/Tune'

import { ReactComponent as CarrierLogo } from '../../../image/carrierLogo.svg'
import { ReactComponent as CiatLogo } from '../../../image/ciat-logo.svg'

import ReportNotification from '../../shared/reportNotification/reportNotification'
import SearchTest from '../../../page/left/Search'

import { changeLang, selectTheme } from '../../../../store/reducer/ui'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'

const StyledAppBar = styled(AppBar)``

const StyledBox = styled(Box)`
    //margin-bottom: 48px;
`

const StyledCiatLogo = styled(CiatLogo)`
    width: 90px;
`

const StyledCarrierLogo = styled(CarrierLogo)`
    width: 90px;
`
const StyledSelect = styled(Select)`
    width: 100%;
    color: inherit;
`

const StyledMenuItem = styled(MenuItem)`
    display: block;
`

export default function PrimarySearchAppBar() {
    const isCiat = useAppSelector((state) => selectTheme(state.ui))
    const loading: boolean = useAppSelector((state) => state.ui.loading)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const [_, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null)
    const dispatch = useAppDispatch()

    const isMenuOpen = Boolean(anchorEl)

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
        handleMobileMenuClose()
    }

    interface CountryType {
        name: string
        flag: string
        lang: string
        code: string
        enabled?: boolean
    }

    const countries: readonly CountryType[] = [
        {
            name: 'Dutch',
            flag: 'nl',
            lang: 'nl',
            code: 'nl-NL',
            enabled: true,
        },
        {
            name: 'English',
            flag: 'us',
            lang: 'en',
            code: 'en-US',
            enabled: true,
        },
        {
            name: 'French',
            flag: 'fr',
            lang: 'fr',
            code: 'fr-FR',
            enabled: true,
        },
        {
            name: 'German',
            flag: 'de',
            lang: 'de',
            code: 'de-DE',
            enabled: true,
        },
        {
            name: 'Italian',
            flag: 'it',
            lang: 'it',
            code: 'it-IT',
            enabled: true,
        },
        {
            name: 'Russian',
            flag: 'ru',
            lang: 'ru',
            code: 'ru-RU',
            enabled: true,
        },
        {
            name: 'Polish',
            flag: 'pl',
            lang: 'pl',
            code: 'pl-PL',
            enabled: true,
        },
        {
            name: 'Turkish',
            flag: 'tr',
            lang: 'tr',
            code: 'tr-TR',
            enabled: true,
        },
        {
            name: 'Simplified chinese',
            flag: 'cn',
            lang: 'cn',
            code: 'zh-CN',
        },
        {
            name: 'Spanish',
            flag: 'es',
            lang: 'es',
            code: 'es-ES',
            enabled: true,
        },
        {
            name: 'Traditional chinese',
            flag: 'cn',
            lang: 'cn',
            code: 'zh-TW',
        },
        {
            name: 'Portuguese',
            flag: 'pt',
            lang: 'pt',
            code: 'pt-PT',
            enabled: true,
        },
        {
            name: 'Swedish',
            flag: 'sv',
            lang: 'sv',
            code: 'sv-SV',
            enabled: true,
        },
    ]

    const menuId = 'primary-search-account-menu'
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}>
            <MenuItem onClick={handleMenuClose}>
                <StyledSelect
                    onChange={(event: SelectChangeEvent<unknown>) =>
                        dispatch(changeLang(countries[event.target.value].lang))
                    }
                    defaultValue={1}
                    variant='standard'
                    disableUnderline
                    autoWidth={true}>
                    {countries.map((country, index) => (
                        <StyledMenuItem key={country.name} disabled={!country.enabled} value={index}>
                            <img
                                key={country.name}
                                loading='lazy'
                                width='16'
                                src={`https://flagcdn.com/w20/${country.flag.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${country.flag.toLowerCase()}.png 2x`}
                            />
                            {` ${country.name}`}
                        </StyledMenuItem>
                    ))}
                </StyledSelect>
            </MenuItem>
        </Menu>
    )

    return (
        <StyledBox sx={{ flexGrow: 1 }}>
            <StyledAppBar position='static'>
                <Toolbar>
                    <a href={isCiat ? '/ciat' : '/carrier'}>{isCiat ? <StyledCiatLogo /> : <StyledCarrierLogo />}</a>
                    {!window.location.pathname.includes('model') && <SearchTest />}
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <IconButton size='large' aria-label='show 17 new notifications' color='inherit'>
                            <ReportNotification />
                        </IconButton>
                        <IconButton
                            size='large'
                            edge='end'
                            aria-label='account of current user'
                            aria-controls={menuId}
                            aria-haspopup='true'
                            onClick={handleProfileMenuOpen}
                            color='inherit'>
                            <Tune />
                        </IconButton>
                    </Box>
                </Toolbar>
            </StyledAppBar>
            {renderMenu}
            {loading && <LinearProgress />}
        </StyledBox>
    )
}
