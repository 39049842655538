import * as React from 'react'

import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { translate } from 'utils/intl/helper'
import { KEYS } from 'utils/intl/constant'
import { selectList, addModelFiltred } from '../../../store/reducer/model'
import { useAppSelector, useAppDispatch } from '../../../store/hooks'
import SearchBar from '../../component/shared/search/SearchBar'

const Container = styled.div`
    position: relative;
    width: 300px;
    margin: 0 24px;
`

const Search = () => {
    const modelList = useAppSelector((state) => selectList(state.model))
    const intl = useAppSelector((state) => state.ui.intl)

    const dispatch = useAppDispatch()
    const [selected, setSelected] = useState('')

    useEffect(() => {
        dispatch(
            addModelFiltred(
                modelList.filter(({ model }) => model.toLocaleLowerCase().includes(selected.toLocaleLowerCase())),
            ),
        )
    }, [selected, modelList])

    const onInputChange = (_: React.SyntheticEvent, value: string) => {
        setSelected(value)
    }

    const options = modelList.map(({ model }) => ({
        label: model,
        value: model,
    }))

    return (
        <Container>
            <SearchBar
                label={translate(intl, KEYS.UI_SEARCH)}
                options={options}
                onInputChangeCallback={onInputChange}
            />
        </Container>
    )
}

export default Search
