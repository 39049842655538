import { IntlProvider, IntlShape } from 'react-intl'
import { LinearProgress } from '@mui/material'
import { useAppSelector } from '../store/hooks'
import { MuiProvider } from './theme/mui'

import useTranslation from './hooks/useTranslation'

export function ProviderApp({ children }) {
    useTranslation()
    const intl: IntlShape = useAppSelector((state) => state.ui.intl)

    return intl.messages ? (
        <IntlProvider locale={intl.locale} messages={intl.messages}>
            <MuiProvider>{children}</MuiProvider>
        </IntlProvider>
    ) : (
        <LinearProgress />
    )
}
