import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { Button, CardActionArea, CardActions } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

interface Props {
    title: string
    to?: string
    image: string
    description: any
}

const StyledCardMedia = styled(CardMedia)`
    && {
        object-fit: contain;
        height: 96px;
    }
`

export default function MediaCard({ title, to, image, description }: Props) {
    const navigate = useNavigate()

    return (
        <Card sx={{ maxWidth: 345 }} variant='outlined'>
            <CardActionArea onClick={() => navigate(to)}>
                <StyledCardMedia component='img' image={image} alt='green iguana' />
                <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                        {title}
                    </Typography>
                    {description.isCarrier && (
                        <Typography variant='body2' color='text.secondary'>
                            {description.isCarrier ? 'Carrier' : 'Ciat'}
                        </Typography>
                    )}
                </CardContent>
            </CardActionArea>
        </Card>
    )
}
