import { IntlShape } from 'react-intl'
import { KEYS } from './constant'

export interface OptionShape {
    key: string
    description: string, 
    checked: boolean
}

export const translate = (intl: IntlShape, translation: string, isCapitalize = true) => {
    if (intl.messages) {
        const entry = Object.entries(KEYS).find((e) => e[1] === translation)
        if (entry === undefined) console.warn(`${translation} do not have a key`)
        const id: string = entry ? entry[0] : translation
        const text = intl.formatMessage({ id, defaultMessage: translation })
        if (isCapitalize) return text.charAt(0).toUpperCase() + text.slice(1)

        return text
    } else {
        console.error('No translations available')
        return translation
    }
}

export const translateOption = (intl: IntlShape, option: OptionShape, isCapitalize = true) => {
    if (intl.messages) {
        if (Object.keys(intl.messages).includes(option.key)) return translate(intl, option.key, isCapitalize)
        else {
            return translate(
                intl,
                `${option.key}DESCRIPTION|${option.description
                    .replaceAll(/\s+|[.,:]+/g, '')
                    ?.replaceAll(String.fromCharCode(10), '')}`,
                isCapitalize
            )
        }
    }
}
