import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IntlShape, createIntl } from 'react-intl'

//

export interface UiState {
    lang: string
    ciatTheme: boolean
    intl: IntlShape
    loading: boolean
}

const initialState: UiState = {
    lang: 'en',
    ciatTheme: false,
    intl: createIntl({
        locale: 'en',
        messages: undefined,
    }),
    loading: false,
}

export const langSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        changeLang: (state, action: PayloadAction<string>) => {
            state.lang = action.payload
        },
        toggleTheme: (state) => {
            state.ciatTheme = !state.ciatTheme
        },
        changeIntl: (state, action: PayloadAction<IntlShape>) => {
            state.intl = action.payload
            state.loading = false
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
    },
})

export const selectLang = (state: UiState) => state.lang
export const selectTheme = (state: UiState) => state.ciatTheme
export const loading = (state: UiState) => state.loading

export const { changeIntl, changeLang, setLoading, toggleTheme } = langSlice.actions
export default langSlice.reducer
